@import './fonts/dm-sans';
@import './fonts/ibm-plex-mono';

$font-sans: 'DM Sans', sans-serif;
$font-mono: 'IBM Plex Mono', monospace;

$rem-base-font: 16px !default;

@mixin font-size($font-size, $line-height: 0) {
  font-size: $font-size;
  font-size: $font-size / $rem-base-font * 1rem;

  @if $line-height > 0 {
    line-height: $line-height;
    line-height: $line-height / $rem-base-font * 1rem;
  }
}
