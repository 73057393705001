@import './scss/layout';
@import './scss/forms';
@import './scss/standard';
@import './scss/media-queries';
@import './scss/themes/dark';
@import './scss/themes/light';
@import './scss/variables';

html {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: var(--background);

  #root {
    display: flex;
    flex: 1;

    .success {
      color: $success;
    }

    .warning {
      color: $warning;
    }

    .danger {
      color: $danger;
    }

    .info {
      color: $info;
    }
  }
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: var(--scroll-background);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: var(--scroll-background);
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--scroll-thumb);
}
