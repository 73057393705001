@import '../../../scss/fonts';
@import '../../../scss/variables';

.toggle-button {
  width: 56px;
  height: 30px;
  border: 1px solid var(--panel-border);
  border-radius: $spacing-small;
  outline: 0;
  background-color: var(--panel-background-highlight);

  svg {
    color: var(--text-color-secondary);
  }

  .icon-container {
    position: relative;
    left: -11px;
  }

  &.toggle-button--checked {
    background-color: #61e884;

    svg {
      color: #ffffff;
    }

    .icon-container {
      left: 11px;
    }
  }
}
