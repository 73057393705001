@import '../../../scss/fonts';
@import '../../../scss/variables';

.micro-graph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 130px;

  .label {
    @include font-size(10px);

    color: var(--text-color-secondary);
    font-family: $font-sans;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .value {
    @include font-size(16px);

    color: var(--text-color-primary);
    font-family: $font-sans;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .canvas {
    color: var(--accent-primary);

    svg {
      overflow: visible;
    }
  }
}
