$content-width-desktop: 1080px;

$spacing-tiny: 10px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;

$form-input-radius: 8px;

$success: #28a745;
$danger: #dc3545;
$info: #17a2b8;
$warning: #ffc107;
