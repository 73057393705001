@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.search {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 40px 60px;

  @include desktop-down {
    padding: $spacing-small;
  }

  .content {
    flex: 1;
    max-width: $content-width-desktop;

    ul {
      @include font-size(14px);

      margin-left: 40px;
      font-family: $font-sans;
      list-style: none;

      @include tablet-down {
        margin-left: 0;
      }

      li {
        margin-bottom: 10px;
        color: var(--text-color-secondary);

        > span {
          &:first-child {
            display: inline-block;
            width: 120px;
            margin-right: 10px;
            color: var(--accent-primary);
          }
        }
      }
    }
  }
}
