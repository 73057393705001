@import '../../../scss/fonts';
@import '../../../scss/variables';

.message-button {
  position: relative;

  .message-button-btn {
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;

    svg {
      color: var(--text-color-secondary);
    }

    &:hover {
      svg {
        color: var(--text-color-primary);
      }
    }

    &:focus {
      svg {
        color: var(--accent-primary);
      }
    }
  }

  .message-button--message {
    @include font-size(10px);

    position: absolute;
    min-width: 200px;
    animation: fade 2s linear;
    animation-fill-mode: forwards;
    opacity: 1;
    color: var(--accent-primary);
    font-family: $font-sans;
    font-weight: bold;
    text-transform: uppercase;

    &.message-button--message--right {
      top: 3px;
      margin-left: 10px;
    }

    &.message-button--message--top {
      top: -15px;
      left: -10px;
    }
  }

  @keyframes fade {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }
}
