@import '../../../scss/fonts';
@import '../../../scss/media-queries';
@import '../../../scss/variables';

.info-panel {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100px;

  @include phone-down {
    height: 80px;
  }

  .info--labels {
    flex: 1;
    justify-content: center;
    overflow: hidden;
  }

  .value {
    @include font-size(24px);

    margin-top: $spacing-tiny;
    overflow: hidden;
    color: var(--text-color-primary);
    font-family: $font-sans;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;

    .value--small {
      @include font-size(14px);
    }

    @include desktop-down {
      @include font-size(18px);
    }

    @include tablet-down {
      @include font-size(17px);
    }

    @include phone-down {
      @include font-size(16px);
    }
  }

  .icon-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: $spacing-small;

    @include phone-down {
      width: 60px;
      height: 60px;
    }

    .icon-background {
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: $spacing-small;
      opacity: 0.1;

      @include phone-down {
        width: 60px;
        height: 60px;
      }

      &.icon-background--green {
        background-color: #16e1d5;
      }

      &.icon-background--orange {
        background-color: #ff8b5c;
      }

      &.icon-background--blue {
        background-color: #4baaff;
      }

      &.icon-background--purple {
        background-color: #666af6;
      }
    }

    svg {
      z-index: 1;
      flex: 1;
    }
  }
}
