@import '../../../scss/fonts';
@import '../../../scss/variables';
@import '../../../scss/media-queries';

.inclusion-state {
  @include font-size(12px);

  display: flex;
  align-items: center;
  font-family: $font-sans;

  @include tablet-down {
    flex-direction: column;
    align-items: flex-start;
    margin-top: $spacing-tiny;
  }

  .inclusion-state-pill {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: $spacing-small;
    padding: 0 $spacing-tiny;
    border: 0;
    border-radius: 6px;
    outline: none;
    background-color: var(--panel-border);
    color: var(--text-color-primary);
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;

    @include tablet-down {
      margin-bottom: $spacing-small;
    }

    &.inclusion__not-included {
      background-color: $warning;
      color: #ffffff;
    }

    &.inclusion__included {
      background-color: #12bf3f;
      color: #ffffff;
    }

    &.inclusion__no-transaction {
      background-color: var(--accent-primary);
      color: #ffffff;
    }

    &.inclusion__conflicting {
      background-color: $danger;
      color: #ffffff;
    }
  }
}
