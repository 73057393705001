@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-300.woff2') format('woff2'), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-italic.woff2') format('woff2'), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-regular.woff2') format('woff2'), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-500.woff2') format('woff2'), url('../../assets/fonts/ibm-plex/ibm-plex-mono-v6-latin-500.woff') format('woff');
}
