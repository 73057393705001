@import '../../../scss/media-queries';
@import '../../../scss/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: $spacing-small;
  background-color: var(--panel-background);

  @include tablet-down {
    justify-content: flex-start;
    height: 60px;
    padding-left: 0;
  }

  @include phone-down {
    padding-left: $spacing-small;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    max-width: $content-width-desktop;

    .child {
      padding: $spacing-tiny $spacing-medium;
      border-right: 1px solid var(--panel-border);

      @include desktop-down {
        padding: $spacing-tiny;
      }

      &.child-fill {
        flex: 1;
        min-width: 250px;
        padding-right: 40px;

        @include tablet-down {
          min-width: unset;
          padding-right: 0;
        }
      }
    }
  }
}
