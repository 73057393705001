@import '../scss/media-queries';
@import '../scss/variables';

.app {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  min-width: 320px;
  overflow: hidden;

  .scroll-content {
    overflow: auto;
  }

  @include tablet-down {
    .health-indicators {
      padding: $spacing-small 20px;
    }
  }

  @include phone-down {
    .health-indicators {
      padding: $spacing-small 20px $spacing-small 66px;
    }
  }
}
