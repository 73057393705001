.theme-light {
  --background: #f6f9ff;
  --panel-background: #ffffff;
  --panel-background-highlight: #f6f8fc;
  --panel-border: #eef4ff;
  --text-color-primary: #25395f;
  --text-color-secondary: #9aadce;
  --accent-primary: #108cff;
  --bar-color-1: #309cff;
  --bar-color-2: #b7dcff;
  --bar-color-3: #1f629f;
  --bar-color-4: #0a3257;
  --scroll-thumb: #9aadce;
  --scroll-background: #ffffff;
  --drop-shadow: #aaaaaa;
  --dialog-shield: #111111;
}
