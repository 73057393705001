@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.explorer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 60px;

  @include desktop-down {
    padding: $spacing-small;
  }

  .content {
    flex: 1;
    max-width: $content-width-desktop;

    .search-panel {
      margin-top: $spacing-large;
      padding: $spacing-small $spacing-large;

      @include desktop-down {
        margin-top: $spacing-small;
      }
    }

    .info {
      .info-panel + .info-panel {
        margin-left: $spacing-small;

        @include tablet-down {
          margin-top: $spacing-small;
          margin-left: 0;
        }
      }
    }

    .milestones-panel {
      padding: $spacing-large;

      .milestones-panel--milestone {
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing-small;

        .index {
          @include font-size(14px);

          min-width: 100px;
          color: var(--accent-primary);
          font-family: $font-sans;
          font-weight: bold;
        }

        a {
          @include font-size(14px);

          min-width: 100px;
          color: var(--text-color-primary);
          font-family: $font-mono;
          text-decoration: none;
          word-break: break-all;
        }
      }
    }
  }
}
