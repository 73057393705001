@import '../../../scss/fonts';
@import '../../../scss/media-queries';
@import '../../../scss/variables';

.graph {
  display: flex;
  flex: 1;
  flex-direction: column;

  .bar-color-1 {
    background-color: var(--bar-color-1);
    fill: var(--bar-color-1);
  }

  .bar-color-2 {
    background-color: var(--bar-color-2);
    fill: var(--bar-color-2);
  }

  .bar-color-3 {
    background-color: var(--bar-color-3);
    fill: var(--bar-color-3);
  }

  .bar-color-4 {
    background-color: var(--bar-color-4);
    fill: var(--bar-color-4);
  }

  .title-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-large;

    .caption {
      @include font-size(10px);

      color: var(--text-color-secondary);
      font-family: $font-sans;
      font-weight: 500;
      text-transform: uppercase;
    }

    .key {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: $spacing-small;

      .key-color {
        width: $spacing-small;
        height: $spacing-tiny;
        border-radius: $spacing-tiny;
      }

      .key-label {
        @include font-size(10px);

        margin-left: $spacing-small;
        color: var(--text-color-secondary);
        font-family: $font-sans;
        font-weight: 500;
      }

      @include tablet-down {
        display: none;
      }
    }
  }

  .canvas {
    flex: 1;
    color: var(--accent-primary);

    svg {
      width: 100%;
      height: 200px;
      overflow: visible;

      .axis-label {
        @include font-size(10px);

        fill: var(--text-color-secondary);
        font-family: $font-sans;
        font-weight: 500;
      }

      .axis-color {
        stroke: var(--text-color-secondary);
        opacity: 0.2;
      }
    }
  }
}
