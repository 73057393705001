@import '../../../scss/fonts';
@import '../../../scss/variables';

.peers-summary {
  padding: $spacing-small;

  .peers-summary--icon-button {
    border: 0;
    outline: 0;
    background: none;
    color: var(--text-color-secondary);
    cursor: pointer;

    &:hover {
      color: var(--accent-primary);
    }
  }

  .peers-summary--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 90px;
    margin-bottom: $spacing-small;
    padding: $spacing-small;
    overflow: hidden;
    border-radius: $spacing-small;
    background-color: var(--panel-background-highlight);

    &:focus {
      border: 0;
    }

    .peer-health-icon {
      width: 16px;
    }

    .peer-id {
      @include font-size(14px);

      margin-left: $spacing-small;
      color: var(--text-color-primary);
      font-family: $font-sans;
      font-weight: 500;
      word-break: break-all;
    }
  }
}
