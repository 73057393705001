@import './fonts';
@import './variables';

.select-wrapper {
  display: inline-block;
  position: relative;

  svg {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 18px;
    color: var(--text-color-primary);
    pointer-events: none;
  }

  select {
    @include font-size(14px);

    height: 40px;
    margin: 0;
    padding: 0 48px 0 20px;
    border: 1px solid var(--text-color-secondary);
    border-radius: $form-input-radius;
    outline: none;
    background-color: transparent;
    color: var(--text-color-primary);
    font-family: $font-sans;
    appearance: none;

    &:focus {
      box-shadow: 0 0 3px 0 var(--accent-primary);
    }

    &:-ms-expand {
      display: none;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 var(--text-color-primary);
    }

    option {
      background-color: var(--panel-background);
      color: var(--text-color-primary);
    }
  }
}

input {
  @include font-size(14px);

  height: 40px;
  margin: 0;
  padding: 0 48px 0 20px;
  border: 1px solid var(--text-color-secondary);
  border-radius: $form-input-radius;
  outline: none;
  background-color: transparent;
  color: var(--text-color-primary);
  font-family: $font-sans;
  appearance: none;

  &:focus {
    box-shadow: 0 0 3px 0 var(--accent-primary);
  }

  &:disabled {
    opacity: 0.5;
  }

  &.input--stretch {
    width: 100%;
  }
}

input[type="file"]::file-selector-button {
  display: none;
}

.file-wrapper {
  display: inline-block;
  position: relative;

  input {
    padding-left: 30px;
  }

  svg {
    position: absolute;
    top: 5px;
    left: 0;
  }
}
