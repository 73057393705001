.theme-dark {
  --background: #22293e;
  --panel-background: #2b3659;
  --panel-border: #212a44;
  --panel-background-highlight: #353f60;
  --text-color-primary: #f1f4fa;
  --text-color-secondary: #9aadce;
  --accent-primary: #108cff;
  --bar-color-1: #309cff;
  --bar-color-2: #1f629f;
  --bar-color-3: #b7dcff;
  --bar-color-4: #e4f2ff;
  --scroll-thumb: #2b3659;
  --scroll-background: #212a44;
  --drop-shadow: #22293e;
  --dialog-shield: #aaaaaa;
}
