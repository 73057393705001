$desktop-width: 1024px;
$tablet-width: 768px;
$phone-width: 480px;

@mixin desktop-down {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-down-only {
  @media (max-width: #{$tablet-width}) and (min-width: #{$phone-width + 1}) {
    @content;
  }
}

@mixin phone-down {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@include phone-down {
  .phone-down-hide {
    display: none;
  }
}
