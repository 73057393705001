@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.peers {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 60px;

  @include desktop-down {
    padding: $spacing-small;
  }

  .content {
    flex: 1;
    max-width: $content-width-desktop;

    .peers--icon-button {
      margin-right: $spacing-small;
      border: 0;
      outline: 0;
      background: none;
      color: var(--text-color-secondary);
      cursor: pointer;

      &:hover {
        color: var(--accent-primary);
      }
    }

    .add-button {
      @include font-size(14px);

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      border: 1px solid var(--text-color-secondary);
      border-radius: $form-input-radius;
      outline: 0;
      background: var(--bar-color-2);
      color: var(--text-color-primary);
      font-family: $font-sans;
      cursor: pointer;

      &:hover {
        color: var(--accent-primary);
      }

      &:focus {
        box-shadow: 0 0 3px 0 var(--accent-primary);
      }
    }

    .peers-panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 1340px) {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }

      .peers-panel--item {
        width: $content-width-desktop / 2 - $spacing-small / 2;
        margin-top: $spacing-small;
        overflow: hidden;

        @media (max-width: 1340px) {
          width: 100%;
        }

        .card {
          padding: $spacing-large;

          .peer-health {
            width: 16px;
            height: 16px;
            margin-right: $spacing-small;
          }

          .peer-id {
            @include font-size(14px);

            @media (min-width: 1340px) {
              @include font-size(12px);
            }

            display: flex;
            flex-direction: column;
            color: var(--text-color-primary);
            font-family: $font-sans;
            font-weight: 500;
          }

          .graph {
            margin-top: 24px;
          }

          .peer-actions {
            justify-content: space-between;
            margin-top: $spacing-small;

            @media (max-width: 720px) {
              flex-direction: column;

              .card--action {
                justify-content: center;
              }

              p {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
