@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.plugins {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 60px;

  @include desktop-down {
    padding: $spacing-small;
  }

  .content {
    flex: 1;
    max-width: $content-width-desktop;

    .plugins-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .plugin {
        width: 49%;
        margin-bottom: $spacing-small;
        padding: $spacing-medium;

        p {
          margin-top: $spacing-small;
          margin-left: 48px;
        }

        @include tablet-down {
          width: 100%;
        }
      }
    }
  }

  a {
    color: var(--accent-primary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
