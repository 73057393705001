@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.visualizer {
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;
  overflow: hidden;

  .canvas {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .action-panel-container {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 30px;
  }

  .stats-panel-container {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 30px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .stats-panel {
      .card--value,
      .card--label {
        text-align: right;
      }
    }
  }

  .key-panel-container {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 30px;
    bottom: 30px;
    left: 30px;
    justify-content: center;
    pointer-events: none;

    .key-panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: $spacing-small;

      .key-panel-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 $spacing-small;

        @include desktop-down {
          width: 110px;
          margin: 0;
        }

        .key-marker {
          width: 12px;
          height: 12px;
          margin-right: $spacing-tiny;
          border-radius: 3px;
        }

        .key-label {
          @include font-size(14px);

          color: var(--text-color-secondary);
          font-family: $font-sans;
          font-weight: 500;
        }
      }
    }
  }

  .info-panel-container {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 30px;
    width: 320px;

    @include phone-down {
      top: 10px;
      left: 10px;
      width: 90%;
    }

    .info-panel--key {
      width: 16px;
      height: 16px;
      margin-right: $spacing-tiny;
      border-radius: 3px;
    }
  }

  .vertex-state--solid {
    background-color: #4caaff;
  }

  .vertex-state--unsolid {
    background-color: #8fe6fa;
  }

  .vertex-state--referenced {
    background-color: #61e884;
  }

  .vertex-state--conflicting {
    background-color: #ff8b5c;
  }

  .vertex-state--milestone {
    background-color: #666af6;
  }

  .vertex-state--unknown {
    background-color: #9aadce;
  }

  .vertex-state--tip {
    background-color: #ffca62;
  }
}
