@import '../../scss/card';
@import '../../scss/fonts';
@import '../../scss/media-queries';

.home {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 60px;

  @include desktop-down {
    padding: $spacing-small;
  }

  .content {
    flex: 1;
    max-width: $content-width-desktop;

    .banner {
      flex: 1;
      height: 140px;

      @include desktop-down {
        height: unset;
      }

      .node-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: $spacing-small;

        @include desktop-down {
          * + .secondary {
            margin-top: $spacing-tiny;
          }
        }
      }

      .banner-curve {
        color: var(--panel-background-highlight);

        @include desktop-down {
          display: none;
        }
      }

      .banner-image {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-radius: 0 16px 16px 0;
        background-color: var(--panel-background-highlight);

        @include desktop-down {
          display: none;
        }
      }
    }

    .messages-graph-panel {
      .graph {
        padding: 24px;
      }
    }

    .info-col {
      align-items: stretch;
      width: 66%;
      overflow: hidden;

      @include desktop-down {
        width: unset;
      }
    }

    .peers-summary-col {
      width: 33%;
      overflow: hidden;

      @include desktop-down {
        width: unset;
      }
    }

    .peers-summary-panel {
      margin-left: $spacing-small;

      @include desktop-down {
        margin-top: $spacing-small;
        margin-left: 0;
      }
    }

    .info-panel + .info-panel {
      margin-left: $spacing-small;

      @include tablet-down {
        margin-top: $spacing-small;
        margin-left: 0;
      }
    }
  }
}
