@import '../../../scss/fonts';
@import '../../../scss/variables';
@import '../../../scss/media-queries';

.message-tangle-state {
  @include font-size(12px);

  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border: 0;
  border-radius: 6px;
  outline: none;
  background-color: var(--panel-border);
  color: var(--text-color-primary);
  font-family: $font-sans;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;

  @include phone-down {
    margin-top: $spacing-small;
  }

  &.message-tangle-state__referenced {
    background-color: #12bf3f;
    color: #ffffff;
  }

  &.message-tangle-state__milestone {
    background-color: #12bf3f;
    color: #ffffff;
  }

  &.message-tangle-state__pending {
    background-color: #9aadce;
    color: #ffffff;
  }

  &.confirmation__no-click {
    pointer-events: none;
  }
}
